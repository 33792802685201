body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: white;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  background: url("assets/bi_invert.png");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.info {
  background-color: red;
  padding: 20px;
  text-align: center;
  color: black;
  font-size: large;
  font-weight: 800;
}

.heading {
  font-family: sans-serif;
  font-size: 1.6rem;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 5%;
  padding-right: 5%;
  font-weight: bold;
  text-align: center;
}

.mainimage {
  width: 45%;
}

.bottomcard {
  width: 33%;
}

.bottomsection {
  justify-content: space-between;
  flex-direction: row;
  max-width: 1280px;
}

.orderbtn {
  display: flex;
  flex: 1;
}

.flexorder {
  flex-direction: row;
}

.bkgnew {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    146deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(152, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    146deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(152, 0, 0, 1) 100%
  );
  background: linear-gradient(
    146deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(152, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#980000",GradientType=1);
}

.customred {
  color: #bd350b;
}

.roadimg {
  background: url("assets/Road.png") no-repeat 0 0;
}

.widthbottom{
  max-width: 1280px;
  width: 1280px;
}

.testibox{
  max-width: 33%;
}

@media screen and (max-width: 1280px) {
  .widthbottom{
    width: 100% !important;
  }
  .widthbottom div div{
    align-items: center;
    justify-content: center !important;
  }
  .testibox{
    width: 90%;
    max-width: 90% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 900px) {
  .mainimage {
    width: 100%;
  }

  .bottomcard {
    width: 100%;
    margin-bottom: 10px;
  }

  .bottomsection {
    flex-direction: column;
  }

  .flexorder {
    flex-direction: column;
  }
}
